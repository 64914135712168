import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {MatSlideToggleModule} from "@angular/material/slide-toggle";
import {MatButtonModule} from "@angular/material/button";
import {MatDividerModule} from "@angular/material/divider";
import {MatIconModule} from "@angular/material/icon";
import {MAT_TOOLTIP_DEFAULT_OPTIONS, MatTooltipDefaultOptions, MatTooltipModule} from "@angular/material/tooltip";
import {MatCardModule} from "@angular/material/card";
import {MatFormFieldModule} from "@angular/material/form-field";
import {MatInputModule} from "@angular/material/input";


const CUSTOM_MAT_TOOLTIP: MatTooltipDefaultOptions = {
    showDelay: 1000,
    hideDelay: 500,
    touchendHideDelay: 1500
};

const MATERIAL_MODULES = [
    MatSlideToggleModule,
    MatButtonModule,
    MatDividerModule,
    MatIconModule,
    MatTooltipModule,
    MatCardModule,
    MatFormFieldModule,
    MatInputModule,
]

@NgModule({
    declarations: [],
    imports: [
        CommonModule
    ],
    providers: [
        {provide: MAT_TOOLTIP_DEFAULT_OPTIONS, useValue: CUSTOM_MAT_TOOLTIP},
    ],
    exports: [...MATERIAL_MODULES]
})
export class MaterialModule {
}
